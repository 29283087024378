@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import "node_modules/bootstrap/scss/_functions.scss";

$darker-gray: #1e1e1e;
$dark-gray: #222222;
$light-gray: #adadad;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Rubik', sans-serif !important;
}

body {
  background-color: $dark-gray !important;;
  color: $light-gray !important;;
}

.nav-header {
  background-color: $darker-gray;

  .navbar-toggler {
    background-color: $light-gray;
    border-radius: 0;
  }

  .navbar-brand {
    color: white;
  }

  a {
    color: $light-gray;
  }

  .disabled {
    color: darken($light-gray, 30%) !important;
  }

  a:hover {
    color: white;
  }
}

.server-list {

  font-size: 1.2rem;

  .table {
    width: 100%;
    background-color: $dark-gray;
    /*border-collapse: separate;
    border-spacing: 0 0.6rem;*/

    th {
      color: white;
    }

    td {
      font-size: 1rem;
      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .server-list-items {
    text-align: left;
  }

  .server-list-header {
    th {
      border-bottom: 1px solid white;
    }
  }

  .day-icon {
    color: #ffc100;
  }

  .night-icon {
    color: #2eaaea;
  }
}

.table-custom {
  --bs-table-bg: $darker-gray !important;
  --bs-table-color: $light-gray !important;
}

@import 'node_modules/bootstrap/scss/bootstrap.scss';